<template>
  <section>
    <nav v-if="navbarItems && navbarItems.length" id="navbar-menu">
      <ul class="horizontal-nav">
        <li
          v-for="item in navbarItems.filter(item =>
            isAuthAux(item.isAuthAuxKey, true)
          )"
          :key="item.name"
          @click="item.onClick"
          class="navbar-item cursor-pointer"
        >
          <vx-tooltip :text="item.text">
            <feather-icon
              v-if="item.iconPack === 'feather'"
              :icon="item.icon"
              :class="{ active: isCurrentRoute(item.name) }"
            />

            <i
              v-if="item.iconPack === 'material'"
              class="material-icons-outlined"
              :class="{ active: isCurrentRoute(item.name) }"
            >
              {{ item.icon }}
            </i>
          </vx-tooltip>
        </li>
      </ul>

      <KonectaDropdown
        :click-outside="outside"
        v-if="templatesPopup"
      >
        <TemplatesList />
      </KonectaDropdown>
    </nav>
  </section>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import WidgetMixin from '@/mixins/widgetMixin.js'

import staffManagementMixin from '@/mixins/staffManagementMixin'

export default {
  name: 'NavbarMenu',
  mixins: [WidgetMixin, staffManagementMixin],
  data() {
    return {
      navbarItems: [],
      isTesting: false,
      templatesPopup: false
    }
  },
  components: {
    KonectaDropdown: () => import('@/components/KonectaDropdown.vue'),
    TemplatesList: () => import('./TemplatesList.vue')
  },
  computed: {
    ...mapState([
      'lang',
      'languageSelected',
      'useCyberbank',
      'session',
      'useStaffManagement'
    ]),
    ...mapGetters('botMaker', [
      'bot',
      'numberOfIntentions',
      'activeVersion',
      'botActiveVersion'
    ])
  },
  watch: {
    'bot.activeVersion'(newVal, oldVal) {
      if (oldVal && newVal !== oldVal) {
        this.pauseWidget()
        this.isTesting = false
        this.getNavbarItems(true)
      }
    },
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      this.pauseWidget()
      this.getNavbarItems()
    }
  },
  methods: {
    ...mapActions('botMaker', ['GET_BOT']),
    async getNavbarItems(reload = false) {
      if (!reload) {
        let botId = this.$route.query.bot || this.$route.query.botId
        if (this.bot === undefined) {
          if (!botId && this.$route.params) {
            botId = this.$route.params.bot
          }
          await this.GET_BOT(botId)
        }
      }

      this.navbarItems = [
        {
          name: 'bot-editor',
          text: this.lang.botMaker.botEditor.navBar.tooltips.mobile[
            this.languageSelected
          ],
          icon: 'smartphone',
          iconPack: 'material',
          onClick: this.navigateToBotView,
          isAuthAuxKey: ['konecta.bot_maker.edit_bot', 'konecta.bot_maker.view_bot']
        }
      ]

      this.navbarItems.push({
        name: 'templates',
        text: 'Templates',
        icon: 'view_quilt',
        iconPack: 'material',
        onClick: this.openTemplatesMenu,
        isAuthAuxKey: 'konecta.bot_maker.edit_bot'
      })

      this.navbarItems.push(
        {
          name: 'tree-view',
          text: this.lang.botMaker.botEditor.navBar.tooltips.tree[
            this.languageSelected
          ],
          icon: 'account_tree',
          iconPack: 'material',
          onClick: this.navigateToTreeView,
          isAuthAuxKey: 'konecta.bot_maker.tree_view'
        },
        {
          name: 'test-bot',
          text: this.lang.botMaker.botEditor.navBar.tooltips.play[
            this.languageSelected
          ],
          icon: this.isTesting ? 'PauseIcon' : 'PlayIcon',
          iconPack: 'feather',
          onClick: this.testBot,
          isAuthAuxKey: 'konecta.bot_maker.try_bot'
        },
        {
          name: 'terminal-bot',
          text: this.lang.botMaker.botEditor.navBar.tooltips.terminal[
            this.languageSelected
          ],
          icon: 'TerminalIcon',
          iconPack: 'feather',
          onClick: this.openTerminal,
          isAuthAuxKey: 'konecta.bot_maker.logs_stream'
        },
        {
          name: 'catalogs',
          text: this.lang.botMaker.botEditor.navBar.tooltips.catalogs[
            this.languageSelected
          ],
          icon: 'BriefcaseIcon',
          iconPack: 'feather',
          onClick: this.navigateToCatalogs,
          isAuthAuxKey: 'konecta.bot_maker.catalogs'
        },
        {
          name: 'integrations',
          text: this.lang.botMaker.botEditor.navBar.tooltips.integrations[
            this.languageSelected
          ],
          icon: 'ZapIcon',
          iconPack: 'feather',
          onClick: this.navigateToIntegrations,
          isAuthAuxKey: 'konecta.bot_maker.integrations'
        },
        {
          name: 'edit-bot',
          text: this.lang.botMaker.botEditor.navBar.tooltips.settings[
            this.languageSelected
          ],
          icon: 'SettingsIcon',
          iconPack: 'feather',
          onClick: this.navigateToSettings,
          isAuthAuxKey: 'konecta.bot_maker.edit_bot'
        },
        {
          name: 'test-groups',
          text: this.lang.botMaker.botEditor.navBar.tooltips.test[
            this.languageSelected
          ],
          icon: 'CheckSquareIcon',
          iconPack: 'feather',
          onClick: this.navigateToTest,
          isAuthAuxKey: 'konecta.test_groups.list'
        }
      )
    },
    isCurrentRoute(name) {
      const routeName = this.$route.name
      return routeName === name
    },
    navigateToBotView() {
      this.$router.push({
        path: '/bot-maker/bot/' + this.bot.service.name,
        query: {
          botId: this.bot.id,
          serviceId: this.bot.service.id,
          version: this.activeVersion
        }
      })
    },
    openTemplatesMenu() {
      this.templatesPopup = true
    },
    openTerminal() {
      this.$router.push({
        path: `/bot-maker/bot/${encodeURI(this.bot.service.name)}/logs-stream`,
        query: {
          serviceId: this.bot.service.id,
          bot: this.bot.id,
          botName: this.bot.service.name,
          version: this.bot.activeVersion
        }
      })
    },
    navigateToCatalogs() {
      this.$router.push({
        path: '/bot-maker/bot/' + this.bot.service.name + '/catalogs',
        query: {
          service: this.bot.service.id,
          bot: this.bot.id,
          version: this.bot.activeVersion
        }
      })
    },
    navigateToIntegrations() {
      this.$router.push({
        path: '/bot-maker/bot/' + this.bot.service.name + '/integrations',
        query: {
          service: this.bot.service.id,
          bot: this.bot.id,
          version: this.bot.activeVersion
        }
      })
    },
    navigateToTreeView() {
      this.$router.push({
        path: '/bot-maker/bot/' + this.bot.service.name + '/tree-view',
        query: {
          service: this.bot.service.id,
          bot: this.bot.id,
          version: this.bot.activeVersion
        }
      })
    },
    navigateToIntentions() {
      this.$router.push({
        path: '/bot-maker/bot/' + this.bot.service.name + '/intentions',
        query: {
          service: this.bot.service.id,
          bot: this.bot.id,
          version: this.bot.activeVersion
        }
      })
    },
    navigateToBots() {
      this.$router.push('/bot-maker/bots')
    },
    navigateToSettings() {
      this.$router.push({
        path: '/bot-maker/bot/' + this.bot.service.name + '/edit',
        query: {
          botId: this.bot.id,
          serviceId: this.bot.service.id,
          version: this.activeVersion
        }
      })
    },
    navigateToTest() {
      this.$router.push({
        path: '/bot-maker/bot/' + this.bot.id + '/test-groups',
        query: {
          botId: this.bot.id,
          serviceId: this.bot.service.id,
          version: this.activeVersion
        }
      })
    },
    testBot() {
      if (
        this.numberOfIntentions >= 2 ||
        /\/(integrations|logs-stream|catalogs)/.test(this.$route.path)
      ) {
        /*let routeData = this.$router.resolve({
          path: '/bot-maker/bot/' + this.bot.id + '/test',
          query: {
            serviceId: this.bot.service.id,
            botName: this.bot.service.name,
            versionId: this.bot.activeVersion
          }
        })*/
        //window.open(routeData.href, '_blank')

        if (!this.isTesting) {
          let versionId =
            this.botActiveVersion && !this.botActiveVersion.active
              ? this.botActiveVersion._id
              : this.activeVersion
          if (!versionId) {
            versionId = this.$route.query.version
              ? this.$route.query.version
              : this.$route.query.versionId
          }
          this.previewWidget(this.bot.id, versionId, res => {
            if (res && res.error) {
              this.$notify.error(
                this.lang.botMaker.widgetPreview.error.title[
                  this.languageSelected
                ],
                this.lang.botMaker.widgetPreview.error.text[
                  this.languageSelected
                ]
              )
            } else {
              this.isTesting = true
              this.getNavbarItems(true)
            }
          }, false, this.bot && this.bot._nlu ? this.bot._nlu._culture : undefined)
        } else {
          this.pauseWidget()
          this.isTesting = false
          this.getNavbarItems(true)
        }
      } else {
        this.insufficientNumberOfIntentions()
      }
    },
    insufficientNumberOfIntentions() {
      this.$vs.dialog({
        color: 'warning',
        title: this.lang.botMaker.botEditor.navBar.title[this.languageSelected],
        text: this.lang.botMaker.botEditor.navBar.text[this.languageSelected],
        acceptText: this.lang.botMaker.accept[this.languageSelected]
      })
    },
    outside: function () {
      this.templatesPopup = false
    }
  },
  beforeMount() {
    if (this.$route.name === 'test-bot') {
      this.isTesting = true
      this.getNavbarItems(true)
    }
  },
  beforeDestroy() {
    this.pauseWidget()
  },
  created() {
    this.getNavbarItems()
  }
}
</script>

<style lang="scss">
#navbar-menu {
  position: relative;
  .horizontal-nav {
    display: flex;

    .navbar-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 5px 10px;

      .con-vs-tooltip {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .feather-icon {
          transition: all 0.3s;
          .feather {
            width: 20px !important;
            height: 20px !important;
          }

          &:hover,
          &.active {
            transform: scale(1.2);
            color: rgba(var(--vs-primary), 1) !important;
          }
        }

        .material-icons,
        .material-icons-outlined {
          font-size: 24px;
          transition: all 0.3s;
          &:hover,
          &.active {
            transform: scale(1.2);
            color: rgba(var(--vs-primary), 1) !important;
          }
        }
      }
    }
  }
  .konecta-dropdown {
    left: 95px;
  }
}
</style>
